<template>
  <div class="user">
    <footer class="table-footer">
      <span></span>
      <el-button type="primary" @click="visible=true">添加账号</el-button>
    </footer>
    <DragTable :header="header" :list-query="list">
      <template #state="{row}">{{row.state == 1 ? '已启用':'已停用'}}</template>
      <template #ctl="{row}">
        <span class="word-color btn" @click="ctl(row,1)">卖家黑名单</span>
        <span class="word-color" @click="ctl(row,2)">{{row.state == 1 ? '停用':'启用'}}</span>
      </template>
    </DragTable>
    <go-dialog v-model="visible">
      <div>
        <el-input v-model="params.account" placeholder="请输入账号"></el-input>
      </div>
      <footer class="table-footer">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="addUser">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      params: {
        user: '',
        pwd: ''
      },
      header: [
        { name: '序号', value: 'id' },
        { name: '账号', value: 'account' },
        { name: '状态', slot: 'state' },
        { name: '操作', slot: 'ctl' },
      ],
      list: {
        data: []
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`/marketCenter/yahoo/account/list`)
      .then(res=>{
        this.list.data = res.data
      })
      
    },
    async ctl(data,type) {
      if(type == 1) {
        this.$router.push(`/goods_order_manager/user_manager/yahoo_seller_black?id=${data.id}&account=${data.account}`)
      }
      if(type == 2) {
        const confirm = await this.$goDialog(`是否确认${data.state == 1?'停用':'启用'}此账号`)
        if(!confirm) return
        this.$axios.post(`/marketCenter/yahoo/account/set_state`,{
          id: data.id,
          state: data.state == 1 ? 2 : 1
        })
        .then(res=>{
          this.$message.success(res.msg)
          this.cancel()
        })
        
      }
    },
    cancel() {
      this.params.account = ''
      this.visible = false
      this.getList()
    },
    addUser() {
      if(!this.params.account) {
        this.$message.warning('请输入账号')
        return
      }
      this.$axios.post(`/marketCenter/yahoo/account/save`,{
        account: this.params.account
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.cancel()
      })
      
    }
  }
}
</script>
<style lang="scss" scoped>
.table-footer{
  margin-bottom: 10px;
}
</style>